.live-meeting-container {
  align-self: flex-start;
  margin-bottom: 50px;
}

.live-meeting-select {
  width: 100%;
  margin-bottom: 16px;
  height: 38px;
}

.live-meeting-card {
  margin-top: 16px;
  text-align: center;
}

.dropdown-container {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  gap: 50px;
  flex-wrap: wrap;
}

.live-demo-input {
  max-width: 250px;
}

.live-meeting-dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}
