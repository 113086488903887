.live-meeting-form-container {
  max-width: 800px;
  margin: auto;
  padding: 20px;
}

.meeting-form-title {
  margin-bottom: 20px;
}

.live-meeting-form-container .ant-input,
.ant-select,
.ant-btn {
  height: 40px;
}

.live-meeting-form-container .react-tel-input {
  padding: 0px;
}

.live-meeting-form-container .react-tel-input .flag-dropdown {
  border-radius: 0 !important; /* Needed to put important here as the styles provided by the third party library has some important styles which are required to be overridden */
}

.pb-logo-header-public-guest-details {
  width: 300px;
}
