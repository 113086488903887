.features {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 52px 20px 64px 20px;
  height: 100%;
  background: #ffffff;
  border-radius: 18px;
  /* min-height:562px; */
}

.meeting-features {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 52px 20px 64px 20px;
  height: 100%;
  background: #ffffff;
  border-radius: 18px;
}

.chart-pie {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 52px 20px 64px 20px;
  height: 100%;
  background: #ffffff;
  border-radius: 18px;
  min-height: 345.8px;
  width: 415px;
  margin-left: 20px;
}

.display-users-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 52px 20px 64px 20px;
  height: 100%;
  background: #ffffff;
  border-radius: 18px;
  min-height: 345.8px;
  width: 900px;
  margin-left: 20px;
  margin-top: 40px;
}

.chart-line {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 52px 20px 64px 20px;
  height: 100%;
  background: #ffffff;
  border-radius: 18px;
  min-height: 345.8px;
  /* width: 600px; */
  margin-left: 50px;
}

.right-content-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  margin-top: 20px;
  margin-bottom: 40px;
  width: calc(86% - 90px);
  margin-left: auto;
  margin-right: auto;
  z-index: 0;
}
.sidebar-wrapper {
  width: calc(14% - -30px);
  position: relative;
}
.right-content-wrapper .features form .container {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.right-content-wrapper .features .showroom-container {
  height: 735px;
  overflow-y: auto;
}
.right-content-wrapper ::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}

/* Track */
.right-content-wrapper ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ededed;
  border-radius: 4px;
}

/* Handle */
.right-content-wrapper ::-webkit-scrollbar-thumb {
  background: #d7d7d7;
  border-radius: 4px;
}
.features .text-approval p,
.message-wrapper p {
  text-align: center;
}

.duration-toolbar .input-label {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  cursor: pointer;
}
.recording-toolbar-container label.input-label,
.recording-toolbar-container .input-select {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  padding: 5px 8px;
  color: #000 !important;
  min-height: 34px;
}
select.form-select[name="state"] {
  border-radius: 15px;
  border: 1px solid #ededed;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #666161;
  padding: 16px;
}
.rbc-time-content .rbc-event-content {
  font-size: 9px;
}
div.rbc-time-content .rbc-event,
div.rbc-time-content .rbc-background-event {
  display: inline-table;
  flex-wrap: wrap;
  word-break: break-word;
  z-index: 1;
}
/* .form--venmo-wrap {
  margin-top: 20px;
} */
div.react-datepicker__day-name,
div.react-datepicker__day,
div.react-datepicker__time-name {
  width: 1.6rem;
  line-height: 1.6rem;
}
div.react-datepicker__day,
div.react-datepicker__time-name {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
}
div.react-datepicker__day-name {
  color: #5c5c5c;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
}
div.react-datepicker__month {
  margin: 0;
  width: 100%;
}

.react-datepicker__current-month {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: #23265f;
  margin-bottom: 12px;
}
div.react-datepicker__day--keyboard-selected,
div.react-datepicker__month-text--keyboard-selected,
div.react-datepicker__quarter-text--keyboard-selected,
div.react-datepicker__year-text--keyboard-selected {
  background: #359cd7;
  border-radius: 30px;
}
span.react-datepicker__navigation-icon {
  top: 9px;
}
div.react-datepicker__header {
  background-color: transparent;
  border-bottom: 0;
}
div.react-datepicker__month-container {
  float: none;
  width: 100%;
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  border-color: #23265f;
}
.features.store-get-more-review {
  height: auto;
}
a.dropdown-item p {
  margin: 0;
  padding: 10px 0;
}
a.view-all-notification.dropdown-item {
  padding: 10px 0;
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: #ebebeb;
}
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 30px !important;
}
p.submit-purebrand {
  margin: 0;
}
.modal-content button.btn.btn-outline-danger.submit-purebrand-bt {
  line-height: 14px;
}
@media only screen and (min-width: 768px) and (max-width: 1100px) {
  .right-content-wrapper {
    width: calc(100% - 24px);
  }
}
@media only screen and (max-width: 768px) {
  button.header-login,
  button.btn.btn-primary {
    min-height: 40px;
    font-size: 15px;
    line-height: 23px;
    padding: 8px;
  }
  div.custom.rbc-calendar .rbc-toolbar button {
    font-size: 12px;
    margin-bottom: 5px;
  }
  .right-content-wrapper {
    width: 100%;
    margin: 40px auto;
  }
  .row.row-meeting-wrapper {
    padding: 0;
  }
  .meeting-wrapper {
    margin-bottom: 0;
    padding: 20px 15px;
  }
  .features.basic-info-wrapper .container {
    max-width: 100%;
    padding: 0 10px;
  }
  div.admin-dashboard-list th,
  table.table-store.table thead th {
    font-size: 15px;
    line-height: 20px;
  }
  table.table-store.table td.no-data-wrap {
    padding: 10px 20px;
    width: 100%;
    min-width: 200px;
  }
  .showroom-wrapper .showroom-data {
    padding: 20px 0;
  }
  .features table.table {
    overflow: auto;
    display: block;
  }
}
@media only screen and (max-width: 600px) {
  .features.store-admin-dashboard-wrap th,
  .features.store-admin-dashboard-wrap td {
    padding: 12px;
    min-width: 180px;
  }
  .meeting-wrapper .rbc-month-view {
    flex: unset;
  }
  .custom.rbc-calendar {
    height: 50vh !important;
  }
  .custom.rbc-calendar .rbc-toolbar button {
    padding: 5px;
  }
  .custom.rbc-calendar .rbc-toolbar .rbc-toolbar-label {
    padding: 0 4px;
    font-size: 10px;
    font-weight: 700;
    font-family: "Mulish";
  }
}
@media only screen and (max-width: 990px) {
  .features.profile-info-form-first .container {
    max-width: 100%;
    padding: 0 10px;
  }
  div.admin-dashboard-list th,
  div.admin-dashboard-list td {
    padding: 15px;
  }
}
.custom.rbc-calendar .rbc-toolbar button {
  font-size: 14px;
  font-weight: 700;
  font-family: "Mulish";
}

.alert.alert-danger.show {
  padding: 4px 10px;
}
.alert.alert-danger.show p {
  margin-bottom: 0;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
}
.react-datepicker-popper {
  width: 100%;
  max-width: 300px;
}
.braintree-option .braintree-option__label {
  text-align: left;
}
.features.inner-tab-payout {
  padding: 20px;
}
.admin-dashboard-list table {
  margin-bottom: 0;
}
table.table-store.table th,
table.table-store.table td {
  padding: 15px 26px;
}
table.table-store.table th {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 1.31484px;
  text-transform: capitalize;
  color: #374151;
  vertical-align: middle;
}
@media only screen and (min-width: 1750px) {
  div.react-datepicker__day-name,
  div.react-datepicker__day,
  div.react-datepicker__time-name {
    max-width: 35px;
    line-height: 35px;
    width: 100%;
  }
}
@media only screen and (min-width: 1100px) and (max-width: 1280px) {
  div.react-datepicker__day-name,
  div.react-datepicker__day,
  div.react-datepicker__time-name {
    width: 1.2rem;
    line-height: 1.2rem;
  }
}
